import { useQuery } from 'react-query';

import { Queries } from 'api/queries';
import userService from 'api/services/user';
import { PendingOwner } from 'types/user';

const usePendingOwners = (callback?: Function) => {
  const { data, isFetching, isError, isSuccess } = useQuery<PendingOwner[]>(
    [Queries.PendingOwners],
    async () => {
      return await userService.GetPendingOwners();
    },
    {
      onSuccess: (data) => {
        if (data != null) {
          if (callback) callback(data);
          return data;
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  return { data, isFetching, isError, isSuccess };
};

export default usePendingOwners;
