import { VegaBox, VegaCard, VegaFlex, VegaFont } from '@heartlandone/vega-react';

import styles from './index.module.css';

const LabelMarker = ({
  color,
  name,
  percentage,
  index,
  selected,
  setSelected,
  isMobile,
  amount,
}: {
  color: string;
  name: string;
  percentage: string;
  index: number;
  selected: boolean;
  setSelected: Function;
  isMobile: boolean;
  amount: string;
}) => {
  return (
    <VegaCard
      className={selected ? `${styles.selected} ${styles.labelMarker}` : styles.labelMarker}
      data-testid={`label-marker-${index}`}
      onClick={() => setSelected()}
    >
      <VegaFlex gap={'size-8'}>
        <div
          style={{
            width: '16px',
            height: '14px',
            background: color,
            borderRadius: '50px',
            marginRight: '10px',
            marginTop: '3px',
          }}
        ></div>
        {!isMobile && (
          <VegaBox style={{ width: '100%' }}>
            <VegaBox>{name}</VegaBox>
            <VegaFlex justifyContent={'space-between'} style={{ width: '100%' }}>
              <VegaFont variant={'font-field-value-sm'}>{percentage}</VegaFont>
            </VegaFlex>
            <VegaFont variant={'font-field-value-sm'}>{amount}</VegaFont>
          </VegaBox>
        )}
        {isMobile && (
          <VegaBox style={{ width: '100%' }}>
            <VegaBox>{name}</VegaBox>
            <VegaFlex justifyContent={'space-between'} style={{ width: '100%' }}>
              <VegaFont variant={'font-field-value-sm'}>{percentage}</VegaFont>
            </VegaFlex>
            <VegaFont variant={'font-field-value-sm'}>{amount}</VegaFont>
          </VegaBox>
        )}
      </VegaFlex>
    </VegaCard>
  );
};

export default LabelMarker;
